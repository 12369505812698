import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { UTXOResponse } from "@/constants/funds-data";
import { useCardanoSerializedHandlers } from "@/providers/cardano-serializer-provider/hooks/use-cardano-serialized-handlers";


interface Props {
  dataRow: UTXOResponse;
}


export const PositionHoldsTableRow = ({ dataRow }: Props) => {

  const { cancelFundRequest } = useCardanoSerializedHandlers();

  const { t } = useTranslation();

  // Assuming cancelFundRequest needs the transaction hash (tx_hash) from dataRow
  const handleCancelClick = () => {
    console.log("Canceling fund request...");
    // You might need to pass other parameters to cancelFundRequest based on its definition
    cancelFundRequest(dataRow.tx_hash, dataRow.output_index, dataRow.amount[0].quantity).then(() => {
      console.log("Fund request canceled successfully");
    }).catch((error) => {
      console.error("Error canceling fund request:", error);
    });
  };


  return (
    <tr className="relative">
      <td>
        <div className="flex items-center gap-3">
          <span className="text-gray-800 text-m md:text-xl font-medium dark:text-white whitespace-nowrap ml-5">
            {dataRow?.fund.fund_name}
          </span>
        </div>
      </td>
      <td className="text-gray-800 text-m md:text-l font-medium text-right dark:text-white">
        {(Number(dataRow?.amount[0].quantity) / 1000000)}
      </td>
      <td className="h-14 text-violet-600 flex justify-end items-center font-medium dark:text-white ml-6">
        <button 
        className="text-s px-24px py-8px rounded-full bg-violet-200 dark:bg-gray-800 hover:dark:bg-gray-700 hover:bg-violet-500 hover:text-white transitions-all duration-300"
        onClick={handleCancelClick}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};
