export const ROUTES = {
  home: {
    title: "home",
    path: "/",
  },
  create: {
    title: "create",
    path: "/create",
  },
  confirmation: {
    title: "confirmation",
    path: "/confirmation",
  },
  createFilled: {
    title: "create",
    path: "/create-filled",
  },
  positions: {
    title: "positions",
    path: "/positions",
  },
  interactions: {
    title: "interactions",
    path: "/interactions",
  },
  interactionsInfo: {
    title: "interactionsInfo",
    path: "/interactions-info/:fund_id",
  },
  details: {
    title: "details",
    path: "/details/:fund_id",
  },
};
