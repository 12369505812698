import React from "react";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { NetworkType } from "@cardano-foundation/cardano-connect-with-wallet-core";

import { twMerge } from "tailwind-merge";
import { useFormikContext } from "formik";

import { CreateFundFormType } from "@/pages/create-fund/types";

import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Input } from "@ui/input/input";
import { Checkbox } from "@ui/checkbox/checkbox";

import styles from "./create-fund-form.module.scss";
import FeeSelectionDropdown from "@/pages/create-fund/components/fee-selection-dropdown/fee-selection-dropdown";

// just do avoid string duplicates
const inputStyles =
  "w-full py-3 px-4 border border-gray-200 rounded focus:border-violet_light dark:focus:border-violet_light hover:border-violet_light dark:hover:border-violet_light dark:bg-gray-900 dark:border-gray-800 dark:text-white placeholder:text-gray-400 dark:placeholder:text-gray-500";
const inputErrorStyles =
  "border-red-200 dark:border-red-400 focus:border-red-400 hover:border-red-400 dark:focus:border-red-800 dark:hover:border-red-800 placeholder:text-red-400 dark:placeholder:text-red-400";

export const CreateFundForm = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext<CreateFundFormType>();

  const { isConnected, accountBalance } = useCardano({
    limitNetwork: NetworkType.TESTNET,
  });

  const isMobile = width < 992;

  return (
    <div
      className={twMerge(
        "h-full w-full md:w-1/2 rounded-lg bg-white p-7 dark:bg-gray-900 border dark:border-gray-800",
        isMobile && "min-h-[737px]",
      )}
    >
      <h3 className="font-sf-pro text-2xl font-semibold text-gray-800 mb-8 dark:text-white">
        {t("create-fund-form.title")}
      </h3>

      <form name="create-fund" className={styles.form}>
        <div className="flex flex-col gap-1 w-full">
          <label className="text-m md:text-l text-gray-800 font-medium dark:text-white flex flex-col gap-1">
            {t("create-fund-form.input-title.name")}
            <Input
              type="text"
              name="fundName"
              value={values.fundName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("create-fund-form.input-title.name")}
              className={twMerge(
                "font-normal leading-normal",
                errors.fundName && touched.fundName && inputErrorStyles,
              )}
            />
          </label>
          {errors.fundName && touched.fundName ? (
            <p className="text-xs text-red-400">{errors.fundName}</p>
          ) : null}
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label className="text-m md:text-l text-gray-800 font-medium dark:text-white flex flex-col gap-1">
            {t("create-fund-form.input-title.description")}
            <textarea
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("create-fund-form.input-title.description")}
              rows={3}
              className={twMerge(
                "font-normal leading-normal",
                inputStyles,
                errors.description && touched.description && inputErrorStyles,
              )}
            />
          </label>
          {errors.description && touched.description ? (
            <p className="text-xs text-red-400">{errors.description}</p>
          ) : null}
        </div>
        <div className="flex flex-col gap-2 items-start w-full">
          <label className="text-m md:text-l text-gray-800 font-medium dark:text-white flex flex-col gap-2">
            {t("create-fund-form.input-title.creator")}
            <Input
              type="text"
              name="creatorName"
              value={values.creatorName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.hide}
              placeholder={t("create-fund-form.input-title.creator")}
              className={twMerge(
                "font-normal leading-normal disabled:text-gray-400 dark:disabled:text-gray-500 transition-all duration-200",
                errors.creatorName && touched.creatorName && inputErrorStyles,
              )}
            />
          </label>
          {errors.creatorName && touched.creatorName ? (
            <p className="text-xs text-red-400">{errors.creatorName}</p>
          ) : null}
          <Checkbox
            name="hide"
            checked={values.hide}
            onChange={handleChange}
            label={t("create-fund-form.input-title.hide")}
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between items-center">
            <label
              htmlFor="input-ada"
              className="text-m md:text-l text-gray-800 font-medium dark:text-white"
            >
              {t("create-fund-form.input-title.ada")}
            </label>
            <button className="hidden md:flex gap-2 items-center h-4">
              <Icon icon={ICON_COLLECTION.walletIcon} className="gray-icon" />
              <span className="text-base font-sf-pro text-gray-400">
                {!isConnected ? (
                  t("create-fund-form.input-title.balance")
                ) : (
                  <>
                    {t("create-fund-form.input-title.balanceFilled")}{" "}
                    {accountBalance}
                  </>
                )}
              </span>
            </button>
          </div>
          <div className={styles["input-with-addon"]}>
            <Input
              type="number"
              name="ada"
              id="input-ada"
              min={100}
              value={values.ada}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="--"
              className={twMerge(errors.ada && touched.ada && inputErrorStyles)}
            />
            <span
              className={twMerge(
                styles["input-addon"],
                "text-gray-400",
                errors.ada && touched.ada && "text-red-400",
              )}
            >
              ADA
            </span>
          </div>
          {errors.ada && touched.ada ? (
            <p className="text-xs text-red-400">{errors.ada}</p>
          ) : null}
        </div>
        <Checkbox
          name="commission"
          checked={values.commission}
          onChange={handleChange}
          label={t("create-fund-form.input-title.commission")}
        />
        <div className="flex w-full justify-between gap-6">
          <div className="flex flex-col gap-1 w-1/2">
            <label className="text-m md:text-l text-gray-800 font-medium dark:text-white flex flex-col gap-1">
              {t("create-fund-form.input-title.address")}
              <Input
                type="text"
                name="address"
                autoComplete="street-address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!values.commission}
                placeholder={t("create-fund-form.input-title.address")}
                className={twMerge(
                  "font-normal leading-normal disabled:text-gray-400 dark:disabled:text-gray-500 transition-all duration-200",
                  errors.address && touched.address && inputErrorStyles,
                )}
              />
            </label>
            {errors.address && touched.address ? (
              <p className="text-xs text-red-400">{errors.address}</p>
            ) : null}
          </div>
          <FeeSelectionDropdown
            className={inputStyles}
            errorClassName={inputErrorStyles}
            disabled={!values.commission}
          />
        </div>
      </form>
    </div>
  );
};
