import React from "react";
import { Formik, FormikHelpers } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { VioletButton } from "@ui/violet-button/violet-button";
import { ICON_COLLECTION } from "@ui/icons/icon-list";

import { Layout } from "@/layout/layout";
import { CreateFundForm } from "./components/create-fund-form/create-fund-form";
import { SelectTokens } from "./components/select-tokens/select-tokens";
import { getCurrentDistribution } from "@/pages/create-fund/helpers/get-current-distribution";

import { CreateFundFormType } from "@/pages/create-fund/types";
import { createFundValidationSchema } from "@/pages/create-fund/validation";
import { ROUTES } from "@constants/routes/routes";

const initValues: CreateFundFormType = {
  fundName: "",
  description: "",
  creatorName: "",
  address: "",
  fee: "",
  hide: false,
  commission: false,
  ada: 0,
  selectedTokenIds: [],
  selectedTokens: {},
};

export const CreateFund = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // todo: connect with API
  const onCreateFund = (
    values: CreateFundFormType,
    helpers: FormikHelpers<CreateFundFormType>,
  ) => {
    navigate(ROUTES.confirmation.path, { state: values });
    // helpers.resetForm();
  };

  return (
    <Layout>
      <Formik
        initialValues={location.state ?? initValues}
        validationSchema={createFundValidationSchema}
        validateOnMount
        onSubmit={onCreateFund}
      >
        {({ isValid, values, submitForm }) => {
          const isFormValid = (() => {
            const currentDistribution = getCurrentDistribution(
              values.selectedTokenIds,
              values.selectedTokens,
            );
            return isValid && +currentDistribution === 100;
          })();

          return (
            <div className="min-h-screen w-full flex flex-col gap-7 py-8 md:pb-16 px-3 md:px-16 dark:bg-dark-bg">
              <div className="flex flex-col md:flex-row gap-6 w-full">
                <CreateFundForm />
                <SelectTokens />
              </div>
              <div className="flex w-full justify-end">
                <VioletButton
                  onClick={submitForm}
                  disabled={!isFormValid}
                  text="Create fund"
                  icon={ICON_COLLECTION.arrowRight}
                  iconClass="white-icon"
                  className="w-full md:w-auto px-32px py-12px"
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </Layout>
  );
};
