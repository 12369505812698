import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { NetworkType } from "@cardano-foundation/cardano-connect-with-wallet-core";

import { Tooltip } from "react-tooltip";
import { twMerge } from "tailwind-merge";

import { Separator } from "@/pages/confirmation/components/separator";
import { Icon } from "@ui/icons/icon";
import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { VioletButton } from "@ui/violet-button/violet-button";
import { useTheme } from "@/components/theme-context/theme-context";
import { FundInteractionForm } from "@/pages/fund-interactions/components/fund-interactions-info/fund-interactions-info";
import { ConfirmationModal } from "@/pages/fund-interactions/components/confirmation-modal/confirmation-modal";
import { useOverlapTrack } from "@/hooks/use-overlap-track/use-overlap-track";

export const RemoveAdaForm = () => {
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    submitForm,
  } = useFormikContext<FundInteractionForm>();
  const { isConnected } = useCardano({ limitNetwork: NetworkType.TESTNET });
  const { t } = useTranslation();
  const { theme } = useTheme();

  const accountBalance = 0;

  const balanceDivRef = useRef<HTMLDivElement>(null);
  const inputTitleRef = useRef<HTMLParagraphElement>(null);

  const isOverlapping = useOverlapTrack({
    absoluteRef: balanceDivRef,
    overlapRef: inputTitleRef,
    additionalDeps: [isConnected, accountBalance],
  });

  const [showModal, setShowModal] = useState(false);

  const cost = (() => {
    const minPrice = 2;
    const price = values.ada / 1000;
    return price > minPrice ? price : minPrice;
  })();

  // todo: youWillGet & youWillPay calculations.

  const handleCancelButton = () => {
    setShowModal(false);
  };

  const handleModalSubmit = async () => {
    await submitForm();
    setShowModal(false);
  };

  return (
    <div className="relative w-full md:w-1/2 min-h-[411px] p-6 pb-16 flex flex-col gap-8 bg-white dark:bg-dark-gray900 rounded-lg font-sf-pro">
      <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
        {t("funds-interactions-forms.remove.title")}
      </h3>
      <div className="w-full flex flex-col gap-5 relative">
        <label
          className={twMerge("flex flex-col gap-1", isOverlapping && "pt-5")}
        >
          <p
            ref={inputTitleRef}
            className="text-l w-fit font-medium text-gray-800 dark:text-white whitespace-nowrap"
          >
            {t("funds-interactions-forms.remove.formTitle")}
          </p>
          <input
            type="number"
            min={0}
            className={twMerge(
              "w-full bg-transparent py-12px px-16px rounded-lg border border-gray-200 focus:border-violet-500 dark:border-gray-800 text-gray-800 dark:text-white font-sf-pro text-l font-medium placeholder:font-sf-pro placeholder:text-gray-800 placeholder:dark:text-white placeholder:text-xl placeholder:font-medium",
              errors.ada &&
                touched.ada &&
                "border-red-400 text-red-400 dark:border-red-400 dark:text-red-400",
            )}
            placeholder="--"
            name="ada"
            disabled={!isConnected}
            value={values.ada}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.ada && touched.ada ? (
            <p className="text-s font-sf-pro text-red-400">{errors.ada}</p>
          ) : null}
        </label>
        <div
          ref={balanceDivRef}
          className="flex items-center gap-2 absolute right-0 top-[2px]"
        >
          <Icon
            icon={ICON_COLLECTION.walletIcon}
            iconColor="#9CA3AF"
            iconSize="16px"
          />
          <span className="text-m font-medium text-gray-400 dark:text-white whitespace-nowrap">
            {!isConnected ? (
              t("funds-interactions-forms.add.balance")
            ) : (
              <>
                {t("funds-interactions-forms.remove.balance")} {accountBalance}
              </>
            )}
          </span>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#fff" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-forms.remove.points.cost")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="Interface fee in ADA"
            />
          </h6>
          <Separator />
          <div className="flex items-center gap-2">
            <span className="text-l text-gray-800 dark:text-white font-medium whitespace-nowrap">
              {values.ada > 0 ? `${cost} ADA` : "--"}
            </span>
          </div>
        </div>
        <div className="flex items-center font-sf-pro gap-3 cursor-pointer">
          <Tooltip
            place="top"
            id="help"
            style={{
              backgroundColor: theme === "dark" ? "#fff" : "#111827",
              color: theme === "dark" ? "#1F2937" : "#fff",
              borderRadius: "6px",
            }}
          />
          <h6 className="text-l h-full text-gray-500 dark:text-gray-200 font-medium whitespace-nowrap flex items-center">
            {t("funds-interactions-forms.remove.points.get")}
            <Icon
              icon={ICON_COLLECTION.helpCircle}
              className="height-12"
              iconColor="#7C3AED"
              tooltip_id="help"
              tooltip_text="ADA received when removing from fund"
            />
          </h6>
          <Separator />
          <div className="flex items-center gap-2">
            <span className="text-l text-gray-800 dark:text-white font-medium whitespace-nowrap">
              {!!values.ada
                ? `${values.ada - values.ada / 100 - values.ada / 1000} ADA`
                : "--"}{" "}
              <span className="text-gray-400 dark:text-gray-500">
                {t("funds-interactions-forms.remove.points.getValue")}
              </span>
            </span>
          </div>
        </div>
      </div>
      <VioletButton
        disabled={!isConnected || !isValid || true}
        onClick={() => setShowModal(true)}
        iconColor="#fff"
        iconSize="24px"
        className="w-full md:w-fit py-2 px-8 absolute left-1/2 bottom-6 -translate-x-1/2"
        text={t("funds-interactions-forms.remove.btn")}
        icon={ICON_COLLECTION.plusCircle}
      />
      <ConfirmationModal
        heading="Remove ADA from Funds?"
        isModalShown={showModal}
        handleCancelButton={handleCancelButton}
        handleSubmitButton={handleModalSubmit}
      />
    </div>
  );
};
