import React from "react";
import { useTranslation } from "react-i18next";
import { PositionHoldsTableRow } from "./positions-holds-table-row";
import { SortButton } from "@/pages/funds-list/components/funds-main/funds-table/sort-button";
import { useTableSort } from "@/hooks/use-table-sort/use-table-sort";
import { UTXOResponse } from "@/constants/funds-data";

interface Props {
  data: UTXOResponse[] | undefined;
}

export const PositionsHoldsTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { order, sortBy, sortOptions, handleSort } = useTableSort();

  
  // todo: should in case of empty list.
  if (!data || data.length === 0) {
    return (
      <div className="p-10 flex flex-col items-center justify-center">
        <h2 className="text-xl font-semibold font-sf-pro text-gray-800 dark:text-white">
          Currently you don't have any open positions
        </h2>
        <h2 className="text-l font-sf-pro text-gray-800 dark:text-white mt-3">
          Start by making your first fund requests with your wallet
        </h2>
      </div>
    );
  }

  return (
    <table className="w-full mt-7">
      <thead>
        <tr className="relative">
          <th className="bg-gradient-to-r from-white dark:from-gray-900 via-white dark:via-gray-900 to-opacity-0 md:bg-transparent h-full sticky md:static font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 text-right font-semibold py-2 px-1 md:px-3">
            <SortButton
              onClick={() => handleSort(sortOptions.name)}
              name="sort-by-name"
              order={order}
              className="w-fit mr-auto"
              label={t("positions.holdsTable.title.name")}
              showArrow={sortBy === sortOptions.name}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 pr-0 px-3 text-left">
            <SortButton
              onClick={() => handleSort(sortOptions.holdAmount)}
              name="sort-by-hold-amount"
              order={order}
              className="w-fit ml-auto"
              label={t("positions.holdsTable.title.ada")}
              showArrow={sortBy === sortOptions.holdAmount}
            />
          </th>
          <th className="font-sf-pro text-s md:text-m whitespace-nowrap text-gray-400 font-semibold py-2 pr-0 px-3 text-right">
            {t("positions.holdsTable.title.action")}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((dataRow, index) => (
          <PositionHoldsTableRow key={index} dataRow={dataRow} />
        ))}
      </tbody>
    </table>
  );
};
