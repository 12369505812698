import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { FundsList } from "@/pages/funds-list/funds-list";
import { CreateFund } from "@/pages/create-fund/create-funds";
import { CreateFundFilled } from "@/pages/create-fund/create-funds-filled";
import { Positions } from "@/pages/positions/positions";
import { FundInteractions } from "@/pages/fund-interactions/fund-interactions";
import { FundInteractionsSec } from "@/pages/fund-interactions/fund-interactions-sec";
import { FundDetails } from "@/pages/fund-details/fund-details";
import { Confirmation } from "@/pages/confirmation/confirmation";
import { ROUTES } from "@constants/routes/routes";

import "@/assets/scss/App.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BookmarkProvider from "@/providers/bookmarks-provider/bookmark-provider";
import CardanoSerializerProvider from "@/providers/cardano-serializer-provider/cardano-serializer-provider";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BookmarkProvider>
        <CardanoSerializerProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.home.path} element={<FundsList />} />
              <Route
                path={ROUTES.interactions.path}
                element={<FundInteractions />}
              />
              <Route
                path={ROUTES.interactionsInfo.path}
                element={<FundInteractionsSec />}
              />
              <Route path={ROUTES.create.path} element={<CreateFund />} />
              <Route
                path={ROUTES.confirmation.path}
                element={<Confirmation />}
              />
              <Route
                path={ROUTES.createFilled.path}
                element={<CreateFundFilled />}
              />
              <Route path={ROUTES.positions.path} element={<Positions />} />
              <Route path={ROUTES.details.path} element={<FundDetails />} />
            </Routes>
          </BrowserRouter>
        </CardanoSerializerProvider>
      </BookmarkProvider>
    </QueryClientProvider>
  );
}

export default App;
