import React, { useRef, useState } from "react";
import { useFormikContext } from "formik";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useOnClickOutside, useToggle } from "usehooks-ts";

import { ICON_COLLECTION } from "@ui/icons/icon-list";
import { Icon } from "@ui/icons/icon";

import { CreateFundFormType, FeeValue } from "@/pages/create-fund/types";

interface Props {
  disabled: boolean;
  className?: string;
  errorClassName?: string;
}

const FEE_OPTIONS: FeeValue[] = ["1", "0.5", "0.3", "0.1", "0.05"];

const FeeSelectionDropdown = ({
  disabled,
  errorClassName,
  className,
}: Props) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<CreateFundFormType>();
  const { t } = useTranslation();
  const [dropdown, toggle, setValue] = useToggle(false);
  const [intersection, setIntersection] = useState(() => ({
    initValue: values.fee,
    hasDropdownOpened: false,
  }));

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const label =
    values.fee !== ""
      ? `${values.fee} %`
      : t("create-fund-form.input-title.fee");

  const handleSelect = (option: FeeValue) => {
    setFieldValue("fee", option);
    toggle();
  };

  // necessary because we need to show an error in case if use opened dropdown and didn't select any of options.
  const toggleWithIntersection = () => {
    if (!dropdown && !intersection.hasDropdownOpened) {
      setIntersection((prevState) => ({
        ...prevState,
        hasDropdownOpened: true,
      }));
    }

    if (intersection.hasDropdownOpened) {
      setFieldTouched("fee");
    }

    toggle();
  };

  const handleClickOutsideWithIntersection = () => {
    if (intersection.hasDropdownOpened) {
      setFieldTouched("fee");
    }

    setValue(false);
  };

  useOnClickOutside(dropdownRef, handleClickOutsideWithIntersection);

  return (
    <div className="w-1/2 flex flex-col gap-1">
      <span className="text-m md:text-l text-gray-800 font-medium dark:text-white">
        {t("create-fund-form.input-title.fee")}
      </span>
      <div ref={dropdownRef} className="relative">
        <button
          name="fee-dropdown-toggle"
          type="button"
          onClick={toggleWithIntersection}
          disabled={disabled}
          className={twMerge(
            className,
            "flex justify-between items-center disabled:bg-gray-50 transition-[border-color] duration-200 disabled:hover:border-gray-200 dark:disabled:hover:border-gray-800",
            dropdown && "border-violet_light",
            errors.fee && touched.fee && errorClassName,
          )}
        >
          <span
            className={twMerge(
              "text-lg text-gray-400 dark:text-gray-500 leading-[27px]",
              errors.fee && touched.fee && "text-red-400 dark:text-red-400",
              label.toLowerCase() !== "fee" && "text-gray-900 dark:text-white",
              disabled && "text-gray-400 dark:text-gray-500",
            )}
          >
            {label}
          </span>
          <div
            className={twMerge(
              "text-gray400 h-[24px] transition-all transform",
              dropdown && "rotate-180",
              errors.fee && touched.fee && "text-red-400",
              label.toLowerCase() !== "fee" && "text-gray-900 dark:text-white",
            )}
          >
            <Icon
              iconSize="24px"
              className="height-24"
              icon={ICON_COLLECTION.chevronDown}
            />
          </div>
        </button>

        <div
          className={twMerge(
            "absolute invisible left-0 bottom-full w-full bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded",
            dropdown && "visible",
          )}
        >
          <ul>
            {FEE_OPTIONS.map((option) => (
              <li key={option}>
                <button
                  name={`select-fee-${option}%`}
                  type="button"
                  onClick={() => handleSelect(option)}
                  className="py-3 px-4 w-full text-gray-800 dark:text-white text-left hover:bg-gray-50 dark:hover:bg-gray-800"
                >
                  {option} %
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {errors.fee && touched.fee ? (
        <p className="text-xs text-red-400">{errors.fee}</p>
      ) : null}
    </div>
  );
};

export default FeeSelectionDropdown;
