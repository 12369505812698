import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  getWalletIcon,
} from "@cardano-foundation/cardano-connect-with-wallet-core";
import { twMerge } from "tailwind-merge";

import { Checkbox } from "@ui/checkbox/checkbox";
import { ConnectWalletFormType } from "@/components/connect-wallet/types";

interface Props {
  availableWallets: string[];
}

export const ConnectWalletForm = ({ availableWallets }: Props) => {
  const { values, errors, isValid, isSubmitting, setFieldValue, submitForm } =
    useFormikContext<ConnectWalletFormType>();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col">
        <h2
          className="font-sf-pro text-2xl font-semibold text-gray-800 dark:text-white"
          data-testid="rich-text"
        >
          {t("connect-wallet-modal.heading")}
        </h2>
        <p
          className="text-m text-black/60 dark:text-gray-200/60 font-normal transition-colors duration-300 mt-2"
          data-testid="rich-text"
        >
          {!!availableWallets.length
            ? t("connect-wallet-modal.label")
            : t("connect-wallet-modal.empty-list-label")}
        </p>
      </div>
      {!!availableWallets.length ? (
        <div className="grid grid-cols-3 gap-6 mt-5 mb-5">
          {availableWallets?.map((availableWallet) => (
            <div
              role="button"
              key={availableWallet}
              className={twMerge(
                "grid place-items-center shadow-lg pointer gap-2 p-6 rounded cursor-pointer dark:text-white",
                values.walletName.toLowerCase() ===
                  availableWallet.toLowerCase() && "bg-violet-200",
              )}
              onClick={(event) => setFieldValue("walletName", availableWallet)}
            >
              <img
                src={getWalletIcon(availableWallet)}
                alt={availableWallet}
                width="30"
              />
              <p
                className={twMerge(
                  "text-m text-black dark:text-white text-center",
                  values.walletName.toLowerCase() ===
                    availableWallet.toLowerCase() && "dark:text-black",
                )}
              >
                {capitalize(availableWallet)}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="py-6">
          <a
            rel="external noopener noreferrer"
            target="_blank"
            href="http://test.com/"
            className="text-md text-black/60 dark:text-gray-200/60 font-normal items-start gap-1 inline-flex group no-underline transition-colors duration-300 hover:text-black/40 hover:dark:text-gray-200/40"
            data-testid="rich-text"
          >
            {t("connect-wallet-modal.empty-list-link")}
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="fill-current group-hover:-translate-y-0.5 group-hover:translate-x-0.5 h-3 w-3 transition-transform duration-300"
            >
              <path
                d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      )}
      {!!availableWallets.length ? (
        <div className="my-5">
          <div className="flex p-3 rounded bg-violet-200 dark:bg-gray-800">
            <Checkbox
              name="agreement"
              label={
                <p className="text-m text-black dark:text-gray-200 font-normal">
                  {t("connect-wallet-modal.privacy.main")}{" "}
                  <a
                    rel="external noopener noreferrer"
                    className="text-violet-700 dark:text-violet-500 hover:text-violet-500"
                    href="test/"
                    target="_blank"
                  >
                    {t("connect-wallet-modal.privacy.disclaimer")}
                  </a>
                  ,{" "}
                  <a
                    rel="external noopener noreferrer"
                    className="text-violet-700 dark:text-violet-500 hover:text-violet-500"
                    href="test/"
                    target="_blank"
                  >
                    {t("connect-wallet-modal.privacy.terms")}
                  </a>{" "}
                  {t("connect-wallet-modal.privacy.middle-part")}{" "}
                  <a
                    rel="external noopener noreferrer"
                    className="text-violet-700 dark:text-violet-500 hover:text-violet-500"
                    href="test/"
                    target="_blank"
                  >
                    {t("connect-wallet-modal.privacy.policy")}
                  </a>
                  .
                </p>
              }
              checked={values.agreementAccepted}
              onChange={() =>
                setFieldValue("agreementAccepted", !values.agreementAccepted)
              }
            />
          </div>
          {!!errors.agreementAccepted && (
            <p className="text-sm text-red-500">{errors.agreementAccepted}</p>
          )}
        </div>
      ) : null}
      <div className="flex flex-col md:flex-row gap-2 md:gap-0 items-center justify-between">
        <button
          className="ml-auto w-full md:w-auto px-32px py-12px flex items-center justify-center gap-3 font-sf-pro text-l font-bold text-white bg-violet_light disabled:bg-violet_light/50 disabled:hover:bg-violet_light/50 rounded-3xl transition-all duration-300 hover:bg-violet900 dark:bg-violet900 dark:hover:bg-violet_light dark:disabled:bg-violet900/50"
          data-testid="button"
          onClick={submitForm}
          disabled={!isValid || isSubmitting || !availableWallets.length}
        >
          {t("connect-wallet-modal.buttons.connect")}
        </button>
      </div>
    </>
  );
};
